import Image from 'next/image'

export function LoadingAppIcon() {
  return <div className="2xl:mx-auto flex items-center justify-center min-h-screen">
    <Image
      className="animate-pulse"
      src="/assets/WarnerRecordedMusic_Symbol_Black.png"
      alt="warner logo"
      width={225}
      height={151}
    />
  </div>;
}
