import type { FC, ReactNode } from "react";
import { useEffect } from "react";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/dist/client/router";
import { SWRConfig } from "swr";
import { Toaster } from "react-hot-toast";
import { useSession } from "next-auth/react";
import { LoadingAppIcon } from "@/components/LoadingAppIcon";


type Props = {
  children: ReactNode;
  secured?: boolean;
};

const fetcher = async (url: RequestInfo | URL) => {
  const res = await fetch(url);
  const data = await res.json();
  if (!res.ok) {
    throw data; // uswSwr handle this and return error that way
  }
  return data;
};


export const AppProtection = ({ children, secured }: Props): JSX.Element => {
  const { status } = useSession();
  const router = useRouter();

  useEffect(() => {
    if(process.env.AUTH === "0") return // for dev env
    if (secured && status === "unauthenticated") router.push("/login");

  }, [status, router]);
  if (!secured) return <>{children}</>;

  if(process.env.AUTH === "0") return <>{children}</>; // for dev env
  if (status === "loading" || status === "unauthenticated") {
    return <LoadingAppIcon />;
  }

  return <>{children}</>;
};


export const AppProviders: FC<Props> = (props) => {
  const router = useRouter();

  const onError = (error: any, key: string) => {
    error.currentUrl = router.asPath;
    console.log("swr:onError", key, { error });
    console.log("e:", error.status, error.message, error.info);
    if (error.status === 400) {
      // alertError(error.message);
    }
    if (error.status === 401) {
      // todo - remove later, that shouldn't happen often (in theory)
      // alertError(error.message);
      router.push(`(/login?returnUrl=${router.asPath}`);
    }
    // alert all errors until improve handling
    if (process.env.NODE_ENV === "production") {
      // todo send error to slack
    }
  };
  return (
    <SWRConfig
      value={{
        onError: onError,
        fetcher,
      }}
    >
      <ThemeProvider attribute="class">
        <AppProtection secured={props.secured}>
          <Toaster position="top-right"
          />
          {props.children}
        </AppProtection>
      </ThemeProvider>
    </SWRConfig>
  );
};
