import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { AppProviders } from "../AppProviders";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import { Suspense } from "react";
/**
 * Import global styles, global css or polyfills here
 * i.e.: import '@/assets/theme/style.scss'
 */
import "../styles/global.css"; // first cause it can overwrite second one
import "@your-org/ui-lib/style/tailwind.css"; // todo don't know where this should come from in production
/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";
// @link https://fontsource.org/docs/variable-fonts
import "@fontsource/inter/variable.css";


// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  session: Session;
};

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, err, session } = appProps;
  return (
    <SessionProvider session={session}>
      {/*@ts-ignore*/}
      <AppProviders secured={Component.secured}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>
        {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
        <Suspense>
          <Component {...pageProps} err={err} />
        </Suspense>
      </AppProviders>
    </SessionProvider>
  );
};


export default MyApp;
